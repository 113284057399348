import React from "react"
import Layout from "../components/Layout"
import styled from "styled-components"
import { StaticImage } from "gatsby-plugin-image"
import {
  TopHeader,
  Section,
  SectionText,
  TwoColumn,
} from "../components/Section"
import Seo from "../components/Seo"

const List = styled.div`
  display: flex;
  flex-wrap: wrap;
`

const ListItem = styled.div`
  display: grid;
  grid-auto-flow: row;
  flex: 15rem;

  h3 {
    font-size: 4rem;
    font-weight: 400;
    margin: 0;
    text-align: center;
  }

  p {
    font-size: 1.2rem;
    font-weight: 700;
    margin-top: 0;
    color: #005b5b;
    text-align: center;
  }
`

export default function About() {
  return (
    <Layout>
      <Seo
        title="About us"
        description="We are reliable demolition & asbestos removal company in North Vancouver with professionals over 10 years of experience. CONTACT NOW to book an appointment."
      />
      <Section>
        <TopHeader>
          <h1>Reliable Demolition & Asbestos Removal</h1>
          <h2>
            Dedicated to delivering a quality service concerning all types of
            asbestos removal, demolition and more
          </h2>
        </TopHeader>
        <TwoColumn>
          <StaticImage
            src="../images/the-team.jpg"
            alt="A&D Demolition Team"
            placeholder="blurred"
            style={{
              boxShadow: "0 25px 50px -12px rgba(0, 0, 0, 0.15)",
              borderRadius: "5px",
            }}
          />
          <StaticImage
            src="../images/about.jpg"
            alt="A&D Demolition employee working at a jobsite"
            placeholder="blurred"
            height={500}
            style={{
              boxShadow: "0 25px 50px -12px rgba(0, 0, 0, 0.15)",
              borderRadius: "5px",
            }}
          />
        </TwoColumn>
        <Section>
          <List>
            <ListItem>
              <h3>2013</h3>
              <p>Year founded</p>
            </ListItem>
            <ListItem>
              <h3>100+</h3>
              <p>Happy clients</p>
            </ListItem>
            <ListItem>
              <h3>200+</h3>
              <p>Projects</p>
            </ListItem>
          </List>
        </Section>
        <TwoColumn>
          <SectionText>
            <h3>Who we are</h3>
            <p>
              A&D Demolition is a North Vancouver based company, that counts
              with over 5 years of experience, the equipment and the team to
              offer you a quality job at a reasonable price, making safety and
              punctuality our priority.
            </p>
            <p>
              Whether you are a homeowner or a business proprietor, you can rely
              on the A&D demolition team and its expertise to demolish
              residential and commercial buildings, or any other renovation
              project.
            </p>
            <p>
              Our experience enables us to do a hassle free demotion project
              without any environmental issues. We are able to demolish your
              facilities and remove the waste in the shortest time possible,
              even when your business is fully operating or temporarily closed.
              Demolition services are available 24 hours a day, 7 days a week.
            </p>
          </SectionText>
          <SectionText>
            <h3>Our expertise</h3>
            <p>
              With many years of industry background, we understand the concerns
              that arise when you are handling the safety of your home or
              project over to a hazardous materials, demolition, deconstruction
              contractor. You’re afraid of proper handling, the risks involved,
              the costs, and of course, the damage. It’s for these very reasons
              that we encourage you to read through our entire site and ask that
              you call us for a personal, FREE consultation.
            </p>

            <p>
              You can be assured that you receive fair pricing, timeliness,
              project quality, the knowledge and the aptitude it takes to
              fulfill multi-service contracts. Above all else, safety is the top
              priority of the A&D demolition team.
            </p>
          </SectionText>
        </TwoColumn>
      </Section>
    </Layout>
  )
}
